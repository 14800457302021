.videoObjectContainer {
    margin-bottom: 25px;
    padding-bottom: 25px;
}

.videoContainer {
    /* DONT CHANGE - This is for resizable videos */
    /* Begin */
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    /* End */
}
.videoContainer iframe {
    /* DONT CHANGE - This is for resizable videos */
    /* Begin */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* End */
}

.videoHeading {
    border-top: solid 5px #1C7FA6;
    padding-top: 15px;
    padding-bottom: 15px;
}


