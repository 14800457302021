html, body {
    height: 100%;
    margin: 0;
    color: #373A42;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Ubuntu', sans-serif;
    /* background-image: linear-gradient(#F2F2F2, white); */
    background-attachment: fixed;
    scroll-behavior: smooth;
}

#root {
    height: 100%;
}

.primaryContainer {
    min-height: 100%;
}

.primaryContainerInterior {
    padding-bottom: 80px;
}

.onHoverMouseChange {
    cursor: pointer;
}

h1, h2, h3 {
    font-family: 'Ubuntu', sans-serif;
    color: #1C7FA6;
    font-weight: 600;
}

a:hover {
    text-decoration: none;
}

.buttonStyling {
    background-color: #1C7FA6;
    border-radius: 5px;
    border: none;
}

.buttonStyling:hover {
    background-color: #1C7FA6;
    opacity: 0.8;
}

.centered {
    text-align: center;
}


.disclaimerHeader {
    padding-top:80px;
    font-size: 40px;
}

.noMatch {
    padding-top: 15em;
}

.leftJustify {
    text-align: left;
}

.greySubtext {
    color: #373A42;
}

.nonHeaderHeading {
    font-size: 1.2em;
    margin-top: 30px;
    margin-bottom: 0px;
}

.smallText {
    font-size: .9em;
}