.quizBlock {
    background-color: #B3E9FF;
    max-width: 100%;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.quizBlock:hover .quizBlockTextContainer {
    opacity: 0;
}

.quizBlockImage {
    width:35%;
    display: block;
    padding-top: 10%;
    padding-bottom: 10%;

}

.quizBlockTextContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .7;
    transition: .4s ease;
    margin: 15px;
    border-radius: 5px;
    background-color: #1C7FA6;
}

.quizBlockText {
    color: white;
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
}


.primaryQuizBlock1 {
    background-color: #1762BD;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.primaryQuizBlock1:hover .primaryQuizBlockTextContainer {
    opacity: 0;
}

.primaryQuizBlock2 {
    background-color: #17B9BD;
}

.primaryQuizBlockImage {
    display: block;
    padding-top: 10%;
    padding-bottom: 10%;
    width: 35%;
}

.primaryQuizBlockTextContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .8;
    transition: .4s ease;
    margin: 15px;
    border-radius: 5px;
    background-color: #1762BD;
}

.primaryQuizBlockTextContainer2 {
    background-color: #17B9BD;
}

.primaryQuizBlockText {
    color: white;
    font-size: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
}

hr {
    width: 40%;
    margin-top: 4rem;
}

.centeredText {
    text-align: center;
}

.headingStyles {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 40px;
    color: #1C7FA6;
    text-align: left;
}

.headingText {
    text-align: left;
}

.generalText {
    font-size: 1.2em;
}

.pageTitleImageContainer {
    height: 100%;
    width: 100%;
    justify-content: center;
    display: flex;
    padding-top: 25px;
}

.pageTitleImage {
    width:65%;
}

.pageTitleRow {
    padding-top: 35px;
    padding-bottom: 40px;
}

.topArticlesTitle {
    margin-top: 40px;
    margin-bottom: 25px;
}

.topBlogsSpacing {
    margin-bottom: 50px;
}