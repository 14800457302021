.pageTitle {
    padding-bottom: 2%;
}

.blogBlock {
    /* border-radius: 5px; */
    border-top: solid 5px #1C7FA6;
    width: 100%;
    max-height: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: white;
    margin-bottom: 25px;
}

.blogBlock:hover {
    border-radius: 3px;
    border: solid 5px #1C7FA6;
    opacity: .7;
    color: #373A42;
    margin-bottom: 20px;
}

.blogBlockImage {
    height: 200px;
    width: 200px;
    align-items: center;
    display: inline;
    justify-content: center;
    border-radius: 5px;
}

.imageContainer {
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 5px;
    text-align: center;
    white-space: nowrap;
    height: 100%;
}

.imgHelper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.textContainer {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}

.subtitle {
    font-weight: 500;
    font-size: larger;
    padding-top: 10px;
}

.blogDetails {
    margin-bottom: 0px;
}

.pagination {
    justify-content: center;
    font-size: 1.15em;
}

.page-link {
    color: #1C7FA6;
}

.page-item.active .page-link {
    background-color: #1C7FA6;
    border-color: #1C7FA6;
}

.page-item:first-child .page-link {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}