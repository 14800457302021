.result-object {
    /* border-radius: 5px; */
    border-top: solid 5px #1C7FA6;
    width: 100%;
    max-height: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: white;
    margin-bottom: 25px;
}

.result-object:hover {
    border-radius: 3px;
    border: solid 5px #1C7FA6;
    opacity: .7;
    color: #373A42;
    margin-bottom: 20px;
}

.result-image-container {
    height: 350px;
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.result-image {
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
}

.resultName {
    padding-bottom: 15px;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.centeredText {
    text-align: center;
}

.resultSpecSpacing {
    padding-bottom: 10px;;
}

.resultSpecCost {
    font-size: 1.35em;
}

.resultSpecTitle {
    font-weight: bold;
    text-align: right;
    padding-right: 0;
}

.resultSpecValue {
    font-style: italic;
    text-align: left;
    padding-left: 0;
}

.resultSpecColon {
    padding-left: 5px;
    padding-right: 5px;
}

.resultsHeader {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: 48px;
    font-weight: 500;
}

.resultsSubHeader {
    width: 100%;
    padding-bottom: 15px;
}

.phonesMessage {
    color: #373A42;
    padding-bottom: 15px;
}

.selectDeviceHeader {
    color: #373A42;
    font-size: 18px;
}

.width100 {
    width: 100%;
}

.rowNoMargin {
    margin-left: 0;
    margin-right: 0;
}

@keyframes gear-rotate {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(90deg); }
    50% { transform: rotate(180deg); }
    75% { transform: rotate(270deg); }
    100% { transform: rotate(360deg); }
  }
  
  .icon-gear {
    animation: gear-rotate 20s linear infinite;
    transform-origin: 50% 50%;
  }
  
.cls-1 {
    fill: #1c7fa6;
}

.cls-1, .cls-2, .cls-3, .cls-4, .cls-5 {
    fill-rule: evenodd;
}

.cls-2 {
    fill: #ffe773;
}

.cls-3 {
    fill: #f59f20;
}

.cls-4 {
    fill: #aaa;
}

.cls-5 {
    fill: #999;
}

.resultLoaderSvg {
    padding-top: 75px;
    width: 25%;
}

@media (max-width: 768px) {
    .resultLoaderSvg {
        width: 75%;
    }
}

/* Matched Preferences */
.matchedPreferencesContainer {
    /* border-radius: 5px; */
    border-top: solid 5px #1C7FA6;
    width: 100%;
    max-height: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: white;
    margin-bottom: 25px;
    margin-left: 15px;
    margin-right: 15px;
}

/* checkmark  */
.checkMark {
    display: inline-block;
    transform: rotate(45deg);
    height: 18px;
    width: 12px;
    border-bottom: 4px solid #13BF19;
    border-right: 4px solid #13BF19;
    margin-right: 8px;
}

.disclaimer {
    margin-bottom: 1.5rem;
    color: grey;
    font-weight: 600;
}

.arrow {
    border: solid grey;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 3px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.card-body {
    padding: 0;
    padding-bottom: .5rem;
    padding-top: .5rem;
}

.card {
    margin-bottom: 1.5rem;
}

.shareButtonSection {
    margin-bottom: 35px;
    margin-top: 25px;
}

.react-share__ShareButton {
    margin-left: 5px;
    margin-right: 5px;
}

.retakeQuizButton {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #13BF19;
    border-color: #13BF19;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.25em;
}

.retakeQuizButton:hover {
    background-color: #13BF19;
    border-color: #13BF19;
    opacity: 0.7;
}

.priceDisclaimer {
    color: grey;
}