.headerCol {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 25px;
}

.headerNavbar {
    background-color: transparent !important;
}

.headerBrand {
    font-size: 2.2em;
    font-weight: 00; 
    color: #1C7FA6;
    font-family: 'Ubuntu', sans-serif;
}

@media (max-width: 400px) {
    .headerBrand {
        font-size: 1.7em;
    }
}

a {     
    color: #4a4c4f;
 }

a:hover { color: green; }

.nav-item {
    font-size: 1.25em;
}

.navItem {
    margin-left: 20px;
    font-size: 1.1em;
}

.headerLogo {
    width: 70px;
    padding-bottom: 5px;
    padding-right: 5px;
}
