.startQuizButton {
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #13BF19;
    border-color: #13BF19;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1.25em;
}

.startQuizButton:hover {
    background-color: #13BF19;
    border-color: #13BF19;
    opacity: 0.7;
}

.introText {
    margin-top: 25px;
    margin-bottom: 25px;
}

.introHeader {
    margin-top: 4em;
}

li {
    padding-top: 10px;
}