/* General Classes Start */

.col-centered{
    float: none;
    margin: 0 auto;
}

.onHoverMouseChange {
    cursor: pointer;
}

.centeredText {
    text-align: center;
}


/* General Classes End */

@media (min-width: 992px) {
    .questionCounter {
        display: none;
    }
}

.questionCounter {
    color: #373A42;
}

.quizName {
    color: #373A42;
    margin-top: 20px;
}

.questionText {
    display: block;
    padding-bottom: 10px;
    text-align: center;
    font-size: 2.5em;
    font-weight: 500;
    font-family: 'Ubuntu', sans-serif;
    color: #1C7FA6;
}
@media (max-width: 768px) {
    .questionText {
        padding-bottom: 25px;
        font-size: 2em;
    }
  }

.questionSubtext {
    display: block;
    padding-bottom: 50px;
    text-align: center;
    font-size: 1.5em;
    font-weight: 300;
    font-family: 'Ubuntu', sans-serif;
    color: #373A42;
}
@media (max-width: 768px) {
    .questionText {
        padding-bottom: 25px;
        font-size: 2em;
    }}


.movementButtons {
    text-align: center;
    padding: 20px;
    padding-top: 60px;
}


.nextButton {
    display: inline-block;
    background-color: #1C7FA6;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.nextButtonArrow {
    border: solid white;
    border-width: 0 7px 7px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-top: 17px;
    margin-right: 8px;
}

.nextButtonDisabled {
    background-color: grey;
}

.nextButtonDisabledArrow {
    border: solid #373A42;
    border-width: 0 7px 7px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-top: 17px;
    margin-right: 8px;
}

.backButton {
    display: inline-block;
    background-color: #1C7FA6;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 50px;
}

.backButtonArrow {
    border: solid white;
    border-width: 7px 0 0 7px;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-top: 17px;
    margin-left: 8px;
}

.nextButton:hover, .backButton:hover {
    opacity: .7;
}

.nextButtonDisabled:hover {
    opacity: 1;
}

.submitButton {
    background-color: #13BF19;
    border-color: #13BF19;
    border-radius: 5px;
    height: 50px;
    width: 100px;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 25px;
}

.submitButtonEnabled:hover {
    opacity: .7;
    background-color: #13BF19;
    border-color: #13BF19;
}


.questionOptionBlock {
    width: 100%;
    max-width: 275px;
    min-width: 205px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .questionOptionBlock {
        max-width: 150px;
        min-width: 150px;
    }
}

@media (max-width: 1260px) {
    .questionOptionBlock {
        max-width: 225px;
        min-width: 150px;
    }
}

.questionOptionSquare {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.questionOptionCircle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #0b8671;
    background-color: #1762BD;
    /* background-color: #17B9BD; */
    border-radius: 50%;
  }

  .questionOptionImage {
    height: 100%;
    display: inline-block;
    width: 70%;
}

.questionOptionText {
    display: block;
    font-weight: bold;
    font-size: 20px;
}

.questionOptionSubtext {
    display: block;
}





/* Question Option Buttons Start */
.checkboxButton {
    margin: 20px;
}

.radioButton {
    margin: 20px;
}

.radioButtonSelected {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: #13BF19;
    background-color: #13BF19;
    display: inline-block;
}

.radioButtonNotSelected {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    border-color: #13BF19;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
}

.checkboxInactive {
    width: 35px;
    height: 35px;
    border-style: solid;
    border-width: 3px;
    border-color: #13BF19;
    border-radius: 5px;
    display: inline-block;
}

.checkboxActive {
    width: 35px;
    height: 35px;
    border-style: solid;
    border-width: 3px;
    border-color: #13BF19;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
}

.checkboxActiveCheckmark {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 16px;
    border-bottom: 6px solid #13BF19;
    border-right: 6px solid #13BF19;
}

.checkboxInactiveCheckmark {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 16px;
    border-bottom: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
}



.input-range {
    width: auto;
    height: 50px;
}

.input-range__track--active {
    background:#1C7FA6;
    height: 10px;
}

.input-range__track {
    height: 10px;
}

.input-range__slider {
    background: #1C7FA6;
    border: #1C7FA6;
    height: 32px;
    width: 32px;
    margin-top: -1.35em;
}

.input-range__label-container {
    font-size: 1.5em;
}

.input-range__label--value {
    display: none;
}

@media (max-width: 768px) {
    .input-range__slider {
        height: 48px;
        width: 48px;
        margin-top: -1.85em;
    }
}



.form {
    margin: 0 auto;
    padding: 100px 30px 100px;
  
    @media (min-width: 800px) {
      max-width: 60%;
    }
}

.costDisplay {
    width: auto;
    font-size: 2.5em;
    font-weight: bold;
}

/* Question Options Buttons End */




/* Sidebar CSS Start */
.sidebarButton {
    display: block;
}

.sidebarButton:hover .sidebarButtonText {
    opacity: .5;
}

.sidebarButtonText {
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.25em;
    color: #1C7FA6;
}

.sidebarButtonActive:hover {
    cursor:pointer;
}

.sidebarButtonInactive {
    color: grey;
}

.sidebarButtonAnsweredCircle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #13BF19;
    background-color: #13BF19;
    display: inline-block;
    vertical-align: middle;
}

.sidebarButtonNotAnsweredCircle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: #13BF19;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    vertical-align: middle;
}

.sidebarButtonInactiveCircle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    border-color: grey;
    background-color: rgba(0, 0, 0, 0);
    display: inline-block;
    vertical-align: middle;
}

/* Sidebar CSS End */