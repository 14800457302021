.containerDiv {
    letter-spacing: .01em;
    font-size: 1.1em;
}

.mainImageContainer {
    /* height: 500px; */
    margin-bottom: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.mainImage {
    border-radius: 5px;
    border: solid 5px #1C7FA6;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
}

.imageSource {
    text-align: center;
    margin-bottom: 3em;
}

.containerDiv img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: solid 5px #1C7FA6;
}

.containerDiv img:hover {
    cursor: pointer;
}

@media (min-width: 768px) {
    .containerDiv img {
        width: 80%;
    }
}

@media (min-width: 1260px) {
    .containerDiv img {
        width: 70%;
    }
}

@media (min-width: 1650px) {
    .containerDiv img {
        width: 50%;
    }
}



#in {
    left: 0;
    background: #FFF;
      outline: none;
  }
  #out, #code {
    left: 50%;
    height: 75%;
    border-left: 1px solid #CCC;
    overflow: auto;
  }
  #code {
    top: 75%;
      height: 25%;
    background: #333;
    color: #DDD;
      margin: 0;
  }
  
.containerDiv h1, h2 {
    text-align: center;
}

.containerDiv h2 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.containerDiv h3 {
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
}

.shareTitle {
    text-align: center;
}
  

.containerDiv li {
    text-align: left;
}

  /** Markdown styling */
.containerDiv br {
      content: 'A';
      display: block;
      margin-bottom: 0.7em;
  }
.containerDiv code {
      padding: 0 3px;
      border: 1px solid #CCC;
      border-radius: 2px;
      background: #FFF;
  }
  pre.code {
      padding: 2px 5px;
      border: 1px solid #DDD;
      background: #FFF;
      border-left-width: 3px;
  }


.shareButtonSection {
    margin-bottom: 35px;
    margin-top: 70px;
}

.react-share__ShareButton {
    margin-left: 5px;
    margin-right: 5px;
}

.blogContainer a {
    color: darkblue;
}



.lightbox {
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
}

.lightbox img {
	/** Pad the lightbox image */
	max-width: 90%;
	max-height: 80%;
    margin-top: 5%;
}

.lightbox:target {
	/** Remove default browser outline */
	outline: none;

	/** Unhide lightbox **/
	display: block;
}